import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {css, Global} from '@emotion/react';
import styled from '@emotion/styled';
import {graphql, navigate} from 'gatsby';
import Seo from '~components/Seo';
import DataViewer from '~utils/DataViewer';
import useSiteSettings from '~utils/useSiteSettings';
import Section from '~components/Section';
import Image from '~components/Image';
import {useSiteState} from '~context/siteContext';
import {mobile, tablet} from '~styles/global';
import MarqueeRow from '~components/MarqueeRow';
import Video from '~components/Video';
import {AnimatePresence, motion} from 'framer-motion';
import resolveLink from '~utils/resolveLink';
import MobileMarqueeRow from '~components/MobileMarqueeRow';
import {Link} from 'gatsby';
import {Plus} from '~components/Svg';

const IndexPage = ({data}) => {
	const {page} = data;
	const [slideIndex, setSlideIndex] = useState(0);
	const [slideIndexMobile, setSlideIndexMobile] = useState(0);
	const [siteState, setSiteState] = useSiteState();
	const [currentProject, setCurrentProject] = useState(undefined);
	const [projectChosen, setProjectChosen] = useState();

	useEffect(() => {
		setSiteState((prevState) => ({
			...prevState,
			pageTitle: undefined,
		}));
	}, []);

	useEffect(() => {
		let timer;

		if (currentProject !== undefined) {
			// Pause the timer
			clearTimeout(timer);
		} else {
			// Restart the timer
			timer = setTimeout(() => {
				if (slideIndex === page?.backgroundSlides?.length - 1) {
					setSlideIndex(0);
				} else {
					setSlideIndex(slideIndex + 1);
				}
			}, 2000);
		}

		return () => clearTimeout(timer);
	}, [currentProject, slideIndex, page]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (slideIndexMobile === page?.backgroundSlidesMobile?.length - 1) {
				setSlideIndexMobile(0);
			} else {
				setSlideIndexMobile(slideIndexMobile + 1);
			}
		}, 2000);
		return () => clearTimeout(timer);
	});

	const leaveMarquees = () => {
		if (!projectChosen) {
			setCurrentProject(undefined);
		} else {
			return;
		}
	};

	const hoverProject = (i) => {
		if (!projectChosen) {
			setCurrentProject(i);
		} else {
			return;
		}
	};

	const enlargeAndNavigate = (project) => {
		setProjectChosen(true);
		setTimeout(() => {
			navigate(resolveLink(project.project));
		}, 1200);
	};

	return (
		<>
			<Seo title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.metaDescription} image={page.seo?.socialImage} />
			<Global
				styles={css`
					body {
						overflow: hidden;
						height: -webkit-fill-available;
						height: fill-available;
					}
				`}
			/>
			<Wrap>
				<BackgroundImages>
					{/* <AnimatePresence> */}
					{/* {currentProject !== undefined && 
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.8 }}
							> */}
					{/* </motion.div>
						}
					</AnimatePresence> */}
					{page?.backgroundSlides?.map((slide, i) => (
						<BackgroundImage key={slide?.asset?._id} image={slide} show={i === slideIndex && currentProject === undefined} cover />
					))}
					{page?.backgroundSlidesMobile?.map((slide, i) => (
						<BackgroundImageMobile key={slide?.asset?._id} image={slide} show={i === slideIndexMobile && currentProject === undefined} cover />
					))}
					{page?.projectLine?.projects.map((project, i) => (
						<BackgroundImage key={project.title + i} image={project?.backgroundImage} show={currentProject === i} cover />
					))}
				</BackgroundImages>
				<Content hidden={siteState.menuOpen || projectChosen}>
					<LineSection>
						<Line />
					</LineSection>
					<Marquees onMouseLeave={() => leaveMarquees()} mbm={!currentProject && currentProject != 0}>
						<MarqueeRow speed={projectChosen || currentProject || currentProject === 0 ? 0 : 0.8}>
							{page?.projectLine?.projects.map((project, i) => (
								<Project key={`rowOne${i}${project.title}`} onClick={() => enlargeAndNavigate(project)} onMouseEnter={() => hoverProject(i)} currentProject={i === currentProject || (!currentProject && currentProject != 0)}>
									<PlusIcon />
									{project.title}
								</Project>
							))}
						</MarqueeRow>
					</Marquees>
				</Content>
			</Wrap>
			<DataViewer data={data} name="data" />
		</>
	);
};

const Wrap = styled.div`
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	min-height: fill-available;
`;
const BackgroundImages = styled.div`
	width: 100%;
	transition: filter 0.2s;
	min-height: 100vh;
	/* mobile viewport bug fix */
	max-height: -webkit-fill-available;
	height: fill-available;
`;

const BackgroundImage = styled(Image)`
	width: 100%;
	opacity: ${(props) => (props.show ? '1' : '0')};
	transition: opacity 0.8s;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	${mobile} {
		display: none;
	}
`;
const BackgroundImageMobile = styled(BackgroundImage)`
	display: none;
	${mobile} {
		display: block;
	}
`;
const Content = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 74px;
	opacity: ${(props) => (props.hidden ? '0' : '1')};
	transition: opacity 0.2s;
	overflow: hidden;
	${tablet} {
		display: none;
	}
	${mobile} {
		padding-bottom: 20px;
	}
`;

const LineSection = styled(Section)`
	display: none;
	${mobile} {
		display: block;
	}
`;
const Line = styled.div`
	${mobile} {
		grid-column: span 12;
		height: 1px;
		background-color: var(--white);
		width: 100%;
		margin-bottom: 4px;
	}
`;
const Marquees = styled.div`
	margin-bottom: 10px;
	mix-blend-mode: ${(props) => (props.mbm ? 'difference' : 'none')};
`;
const Project = styled.button`
	display: inline-block;
	color: var(--white);
	margin-right: 22px;
	opacity: ${(props) => (props.currentProject ? '1' : '0.1')};
	transition: opacity 0.25s;
	font-size: 32px;
	line-height: 1.2;
`;
const PlusIcon = styled(Plus)`
	width: 18px;
	height: 18px;
	margin-right: 4px;
`;

export const query = graphql`
	query HomeQuery {
		page: sanityHomePage {
			title
			seo {
				...seo
			}
			backgroundSlides {
				...imageWithAlt
			}
			backgroundSlidesMobile {
				...imageWithAlt
			}
			projectLine {
				projects: project {
					_key
					backgroundImage {
						...imageWithAlt
					}
					title
					project {
						_type
						thumbnail {
							...thumbnail
						}
						slug {
							current
						}
					}
				}
			}
		}
	}
`;

IndexPage.propTypes = {
	data: PropTypes.object,
};

export default IndexPage;
